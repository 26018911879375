import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import "./utils/i18n"
import "./App.scss"
import { useEffect } from "react"
import { changeLanguage } from "./utils/i18n"
import { getI18nextLng } from "lib/cookie"

notification.config({
  top: 0,
  duration: 3
})
function App() {
  const { orgName } = useStore()

  useEffect(() => {
    if (orgName) {
      changeLanguage(getI18nextLng())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#1C3F38",
          borderRadius: 4,
          fontFamily: "Inter"
          // Alias Token
          // colorBgContainer: "#f0f2f5"
        }
      }}
    >
      <StyleProvider hashPriority="high">
        <StoreProvider>
          <Routes />
          <div id="modal-root"></div>
        </StoreProvider>
      </StyleProvider>
    </ConfigProvider>
  )
}

export default App
