export function getPermissions() {
  return localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "[]"
}

export function convertPermission(permission) {
  const objPermission = {}
  for (let j = 0; j < permission?.length; j++) {
    objPermission[permission[j]?.object] = true
  }
  return objPermission
}
